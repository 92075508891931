import React from "react";
import { Link } from 'gatsby';

import Layout from "../components/layout";
import SEO from "../components/seo";

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <section className="container">
      <h1 className="is-size-1">NOT FOUND</h1>
      <p>Die Seite wurde nicht gefunden.</p>
      <Link to="/">Zur Startseite</Link>
    </section>
  </Layout>
)

export default NotFoundPage
